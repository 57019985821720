@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap");

.bg {
  background-image: url('./img/paper.jpeg') !important;
  width: 100% !important;
  height: 955px !important;
  background-size: cover;
}
.no-bg {
  background: transparent !important;
  width: 100% !important;
  height: 955px !important;
  background-size: cover;
}

.banner {
  padding-top: 150px;
}

.logo-preview {
  width: 80px;
}

.heading-preview {
  text-align: right;
  font-size: 40px;
  font-family: "Alex Brush", cursive;
  border-bottom: 0px;
}

.list {
  border-top: 2px solid red;
  margin-top: -18px;
}

.sub p {
  font-size: 11px;
  text-align: right;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.identitas p {
  font-size: 11px;
  font-family: "PT Sans Caption", sans-serif;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #000 !important;
}

.identitas .name {
  font-size: 10px;
  background: #cccccc;
  padding: 2px 5px 2px 5px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000 !important;
}

.identitas .sub-identitas {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #000 !important;
}
.bold {
  font-weight: 800 !important;
}

.table-border {
  padding-top: 15px;
  width: 100%;
  z-index: 2;
  margin: auto;
  min-height: 615px;
}

.img-overlay {
  margin-top: 48px;
  min-height: 550px;
  margin-bottom: 0px;
}
.img-overlay2 {
  margin-top: 48px;
  padding-bottom: 20px;
  min-height: 540px;
  margin-bottom: 0px;
}
.img-overlay3 {
  margin-top: 48px;
  padding-bottom: 20px;
  min-height: 540px;
  margin-bottom: 0px;
}

.img-overlay img {
  width: 350px;
  opacity: 0.1;
  z-index: 1;
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: 25%;
  right: 0;
}

.border-delete {
  border-right: 1px solid transparent !important;
}

.subject {
  text-align: left !important;
  font-size: 10px;
  padding: 1px !important;
  color: #000 !important;
}
.subject2 {
  text-align: center !important;
  font-size: 10px;
  padding: 1px !important;
  color: #000 !important;
}
.subject3 {
  text-align: left !important;
  font-size: 10px;
  padding: 0px 0px 0px 15px !important;
  color: #000 !important;
}

.th-preview {
  text-align: center !important;
  font-size: 10px;
  padding: 1px !important;
  padding-bottom: 0px !important;
  border: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  border-right: 1px solid #000 !important;
  color: #000 !important;
}
.th-preview2 {
  font-size: 10px;
  padding: 1px !important;
  padding-bottom: 0px !important;
  border: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  border-right: 1px solid #000 !important;
  color: #000 !important;
}

.td-preview {
  text-align: center !important;
  font-size: 10px;
  padding: 0px !important;
  margin: 0px !important;
  border: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  border-right: 1px solid #000 !important;
  color: #000 !important;
}
.td-preview2 {
  text-align: center !important;
  font-size: 10px;
  padding: 0px !important;
  margin: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  color: #000 !important;
}
.td-preview3 {
  font-size: 10px;
  padding: 0px !important;
  margin: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  color: #000 !important;
}
.td-preview4 {
  font-size: 9px !important;
  padding: 0px !important;
  margin: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  color: #000 !important;
}

.curicular {
  border-bottom: 1px solid #000 !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  padding: 8px !important;
  margin: 0px !important;
}

.th-color {
  background-color: #bbbbbb;
}

.th-color2 {
  background-color: #eeeeee;
}

.maximum {
  border-bottom: 1px solid #000;
  padding: 0px !important;
  margin: 0px !important;
}

.font {
  font-size: 11px !important;
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  color: #000 !important;
}
.font2 {
  font-size: 9px !important;
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  color: #000 !important;
}

.checkbox-preview {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid #cccccc;
  float: left;
  margin-right: 20px;
}

.comment {
  background-color: #dddddd;
  color: #000 !important;
  padding: 0px !important;
  margin-left: 20px;
}

.comment p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #000 !important;
}
.containerr {
  margin-left: 20px;
  margin-right: 20px;
}

.sub-comment {
  font-size: 15px;
  font-family: "Charm", cursive;
  font-weight: bold;
  padding-left: 5px !important;
  text-align: left;
  color: #000;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.footer {
  margin-bottom: 30px;
}

.sub-footer {
  border-top: 2px solid grey;
  font-size: 14px;
  font-family: "Charm", cursive;
  font-weight: bold;
  padding: 0px !important;
  color: #000;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.table-responsive {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.actived {
  position: absolute !important;
  top: 0 !important;
}

.non {
  margin-top: 0px !important;
  position: absolute !important;
  top: 0 !important;
}

.actived2 {
  width: 750px !important;
  margin-left: 20px !important;
}

.non2 {
  margin-top: 150px;
  width: 900px !important;
  margin-left: 20px !important;
}

.mid-actived {
  position: absolute !important;
  top: 0 !important;
}

.mid-non {
  margin-top: 0px !important;
  position: absolute !important;
  top: 0 !important;
}

.mid-actived2 {
  width: 700px !important;
  padding-left: 64px !important;
  padding-right: 42px !important;
}

.mid-non2 {
  margin-top: 180px;
  width: 700px !important;
  padding-left: 64px !important;
  padding-right: 42px !important;
}

.sub-footer {
  font-size: 14px;
  font-family: "Charm", cursive;
  font-weight: bold;
  padding: 0px !important;
  color: #000;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.name-footer {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  padding: 0px !important;
  color: #000 !important;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}


@media only screen and (max-width: 600px) {
  .table-border {
    padding-top: 35px;
    width: 100%;
    z-index: 2;
    margin: auto;
    min-height: 635px;
  }
  .mid-non2 {
    margin-top: 200px;
    width: 700px !important;
    padding-left: 64px !important;
    padding-right: 42px !important;
  }
}